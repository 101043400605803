const initFirebase = () => {
    var firebaseConfig = {
        apiKey: "AIzaSyAdKnRLggS2Nr2gOy7PWekYXCwh3BDiUvE",
        authDomain: "saalari-79d94.firebaseapp.com",
        projectId: "saalari-79d94",
        storageBucket: "saalari-79d94.appspot.com",
        messagingSenderId: "281356328241",
        appId: "1:281356328241:web:9e63c56bb7c969116da410",
        measurementId: "G-3MC702XBVZ"
    };

    if (!window.fbstorage) {
        firebase.initializeApp(firebaseConfig);
        window.fbstorage = firebase.storage().ref("Assets");
    }
}

export const uploadAsset = (file) => {

    return new Promise((resolve, reject) => {
        initFirebase();

        let uploadTask = window.fbstorage.child(file.name).put(file);
        uploadTask.on('state_changed',
            null, // state changed
            (error) => reject(error), // error
            () => {  // success
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    resolve(downloadURL)
                });
            })
    })

}