<template>
  <div>
    <label class="block" v-if="label"
      >{{ label }}</label
    >
    <input type="file" ref="fileInput" hidden @change="handleFileChange" />

    <div class="filesList">
      <div
        class="filesListItem"
        :class="{ '--uploaded': files[index] && files[index].url }"
        v-for="(i, index) in maxFiles || 1"
        :key="index"
        @click="handleFileListItemClick(index)"
      >
        <!-- Loading -->
        <div
          class="message"
          v-if="files[index].loading"
          v-html="uploadingMessage"
        ></div>

        <template v-else>
          <!-- File Types Preview -->
          <template v-if="files[index].url">
            <!-- image -->
            <img :src="files[index].url" />
          </template>

          <!-- Label -->
          <div class="message" v-else v-html="label"></div>

          <!-- Btn Delete -->
          <div
            class="btnDelete"
            @click="(e) => deleteFile(index, e)"
            v-if="files[index].url"
          >
            ❌
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Compressor from "compressorjs";
export default {
  name: "VueEasyMultiFileUpload",
  props: {
    value: { type: null },
    label: "",
  },
  data() {
    return {
      uploadingMessage: "Loading...",
      files: [],
      activeIndex: null,
      maxFiles: 1,
    };
  },
  created() {
    if (this.value && this.value.length) {
      let paths = this.value.split("|");
      for (let i = 0; i < this.maxFiles; i++) {
        this.files.push({
          url: paths[i] == "null" ? null : paths[i],
          loading: false,
        });
      }
    } else
      this.files = Array(this.maxFiles).fill({
        url: null,
        loading: false,
      });
  },
  methods: {
    handleFileListItemClick(index) {
      if (this.files[index] && this.files[index].url) return;
      this.activeIndex = index;
      this.$refs.fileInput.click();
    },
    deleteFile(index, e) {
      if (confirm("Are you sure you want to delete?")) {
        Vue.set(this.files, index, { url: null, loading: false });
        this.update();
      }
      e.stopPropagation();
    },
    update() {
      let toEmit = this.files.map((file) => {
        if (file && file.url) return file.url;
        else if (file.file) return file.file;
        else return null;
      });
      this.$emit("input", toEmit);
      this.$refs.fileInput.value = null;
    },
    async handleFileChange(e) {
      let file = e.target.files.item(0);
      // validate
      let error = await this.validateFile(file);
      if (error) {
        alert(error);
        return;
      }
      let fileData = {
        loading: true,
        url: null,
      };
      // Compress Image
      try {
        let convertedFile = await this.compressImage(file);
        file = new File([convertedFile], file.name);
      } catch (e) {
        console.log(e);
      }
      fileData.file = file;
      Vue.set(this.files, this.activeIndex, fileData);
      this.update();
    },
    processValueProp(val) {
      if (!val) return;
      this.files = Array(this.maxFiles).fill({
        url: null,
        loading: false,
      });
      let urls = val.split("|");
      urls.forEach((url, i) => {
        Vue.set(this.files, i, {
          url: url == "null" ? null : url,
          loading: false,
        });
      });
    },
    async validateFile(file) {
      let error = null;
      if (file.size > 10 * 1024 * 1024) error = "File size too large";
      return error;
    },
    async compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.4,
          convertSize: 2000000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err.message);
          },
        });
      });
    },
  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal + "" !== oldVal + "") {
        this.processValueProp(newVal);
      }
    },
  },
};
</script>

<style scoped>
.filesList {
  display: flex;
  flex-wrap: wrap;
}
.filesList > .filesListItem {
  border-radius: 0.25rem;
  border: 1.5px dashed lightgray;
  background-color: whitesmoke;
  text-align: center;
  cursor: pointer;
  margin: 0.5rem;
  position: relative;
  font-size: 0.8rem;
  font-family: Arial, Helvetica, sans-serif;
  color: grey;
  width: 150px;
  height: 175px;
}
.--uploaded {
  border-color: #373737 !important;
  color: #373737 !important;
}
.filesListItem .btnDelete {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 0.6rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  transform: translate(50%, -50%);
  border: 1.5px dashed #373737;
  background-color: whitesmoke;
  opacity: 0;
  transition: all 0.15s;
}
.filesListItem:hover .btnDelete {
  opacity: 1;
}
.filesListItem img,
video,
div.message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  object-fit: cover;
}
.fileName {
  font-size: 0.8rem;
  overflow: hidden;
}
</style>